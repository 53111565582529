import React from 'react';
import { Helmet } from 'react-helmet';
import './OverhaulFuel.css';

function OverhaulFuel() {
  return (
    <div className="overhaul-fuel">
      <Helmet>
        <title>Fuel Boosters & Exhaust Valve Actuator Overhaul - Di-Tech Marine Engineering</title>
        <meta name="description" content="Explore our comprehensive overhaul services for fuel boosters and exhaust valve actuators. Contact us today to elevate your fleet's performance and efficiency." />
        <meta name="keywords" content="Fuel valve overhaul, fuel boosters overhaul, exhaust valve actuators, marine engine maintenance, ship engineering services" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Fuel Boosters & Exhaust Valve Actuator Overhaul - Di-Tech Marine Engineering" />
        <meta property="og:description" content="Explore our comprehensive overhaul services for fuel boosters and exhaust valve actuators. Contact us today to elevate your fleet's performance and efficiency." />
        <meta property="og:url" content="https://di-tech.eu/overhoal-fuel-boosters" />
        <meta property="og:type" content="website" />
      </Helmet>

      <h1 className="headingofOverhaulFuel">Overhaul of Fuel boosters/Exhaust valve actuators</h1>
      <p></p>
      <p className='extraParagraph'>
        Contact us today to explore how our engineering services can elevate your fleet's performance and efficiency.
      </p>
    </div>
  );
}

export default OverhaulFuel;
