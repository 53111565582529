import React from 'react';
import { Helmet } from 'react-helmet';
import './AlphaSystem.css';

const AlphaSystem = () => {
  return (
    <div className="alpha-system">
      <Helmet>
          <title>Alpha Lubricator Upgrade - Slow Steaming Kit - Di-Tech Marine Engineering</title>
          <meta name="description" content="Explore the Alpha Lubricator Upgrade and Slow Steaming Kit offered by Di-Tech Marine Engineering. Learn about its benefits, scope of content, and how it optimizes cylinder oil consumption for marine engines." />
          <meta name="keywords" content="Alpha Lubricator Upgrade, Slow Steaming Kit, Di-Tech Marine Engineering, cylinder oil consumption, marine engines, lubrication optimization, engine performance, fuel efficiency, 2-stroke engine maintenance, 2-stroke specialist, 2-stroke workshop" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content="Alpha Lubricator Upgrade - Slow Steaming Kit - Di-Tech Marine Engineering" />
          <meta property="og:description" content="Discover the Alpha Lubricator Upgrade and Slow Steaming Kit. Learn how Di-Tech Marine Engineering optimizes cylinder oil consumption and enhances marine engine performance." />
          <meta property="og:url" content="https://di-tech.eu/alpha-system" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={'${process.env.PUBLIC_URL}/pics/AlphaLubImage1.webp'} />
          <meta property="og:image:alt" content="Alpha Lubricator Upgrade" />
      </Helmet>

      <h1 className='heading-of-Lubricator-page'>Alpha Lubricator Upgrade</h1>
      <p className='second-heading-of-Lubricator-page'>Slow Steaming Kit</p>
      
      <div className="row">
        <div className="row-item">
          <img src={`${process.env.PUBLIC_URL}/pics/AlphaLubImage1.webp`} alt="ship engine repair companies" className="row-image" />
          <img src={`${process.env.PUBLIC_URL}/pics/AlphaLubImage2.webp`} alt="ship engine repair companies" className="row-image" />
          <h2>Reduction of cylinder oil costs</h2>
          <p>
            In early 2012, a new layout of the Alpha Lubricator in new MC engines was introduced. Along with a number of design improvements, the injection volume and frequency has been changed in order to optimize the cylinder oil consumption at low load.
          </p>
          <p>
            Now, Alpha Lubricator Upgrade is introduced as a retrofit solution, optimizing cylinder oil consumption in existing engines.
          </p>
        </div>
        <div className="row-item">
          <p>
            A consequence of substantial over-lubrication at low load is generating deposits on the piston crown and rings, increasing the risk of damage to the cylinder liners. In addition, over-lubrication generates excess costs for unnecessary lubrication oil.
          </p>
          <p>
            The Alpha Lubricator Upgrade is a major improvement of the system. By adapting the new lubricator layout, a complete lubrication optimization at all engine loads is now possible.
          </p>
          <h2 className='Lubricatorlist'>Benefits of upgrading the system: </h2>
          <ul>
            <li>Cylinder lubrication oil savings of 20-50% at low load</li>
            <li>More accurate feed rate control at all engine loads</li>
            <li>Minimizing over-lubrication at low load, reducing deposits, hence improving the cylinder condition</li>
            <li>Implementation of latest design features</li>
            <li>Upgraded interface by latest software version.</li>
          </ul>
        </div>
      </div>

      <div className="row-wrapper">
        <h2 className='middle-heading'>Alpha Lubricator Upgrade is beneficial to all large bore MC engines (70-98 bore) and older installations in small bore engines.</h2>
        <div className="row">
          <div className="row-item">
            <h4>Scope of content</h4>
            <p>
              The Alpha Lubricator Upgrade, Slow Steaming Kit, comprises a new MCU, including the latest software, and the improved lubricator layout:
            </p>
            <p>
              <strong>MCU Mk 2: </strong>New hardware version, plug and play in existing ALCU. New software with upgraded algorithm and system interface
            </p>
          </div>
          <div className="row-item">
            <p>
              <strong>Lubricators: </strong>Complete lubricators with all new design features (recommended at the standard 5-year lubricator overhaul) or
            </p>
            <p>
              <strong>Lubricator kits: </strong>Parts to modify the lubricators for optimized slow steaming operation (require that solenoid valves, accumulators, etc. are in good working condition).
            </p>
          </div>
        </div>
      </div>

      <div className="last-images-container">
        <img src={`${process.env.PUBLIC_URL}/pics/AlphaLubImage3.webp`} alt="ship engine repair companies" className="row-image" />
        <p>
          The new lubricator layout reduces the engine power level at which the algorithm, controlling the cylinder oil feed rate, changes. The “breakpoint” is moved to the left. The Alpha Lubricator Upgrade enables the cylinder oil feed rate to follow a lower curve. The marked area in the figure above corresponds to the potential lubrication oil savings from upgrading the system. 
        </p>
        <img src={`${process.env.PUBLIC_URL}/pics/AlphaLubImage4.webp`} alt="ship engine repair companies" className="row-image" />
      </div>
    </div>
  );
};


export default AlphaSystem;



