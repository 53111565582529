import React from 'react';
import { Helmet } from 'react-helmet';
import './OverhaulExhaust.css';

function OverhaulExhaustt() {
  return (
    <div className="overhaul-exhaust">
      <Helmet>
        <title>Exhaust Valve Overhaul Services - Di-Tech Marine Engineering</title>
        <meta name="description" content="Explore our comprehensive overhaul services for exhaust valves onboard and in workshop. Contact us today to elevate your fleet's performance and efficiency." />
        <meta name="keywords" content="exhaust valve overhaul, marine exhaust systems, ship maintenance, marine engineering services" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Exhaust Valve Overhaul Services - Di-Tech Marine Engineering" />
        <meta property="og:description" content="Explore our comprehensive overhaul services for exhaust valves onboard and in workshop. Contact us today to elevate your fleet's performance and efficiency." />
        <meta property="og:url" content="https://di-tech.eu/overhoal-exhaust" />
        <meta property="og:type" content="website" />
      </Helmet>

      <h1 className="headingofOverhaulExh">Overhaul of Exhaust valves onboard and in workshop</h1>
      <p></p>
      <p className='extraParagraph'>
        Contact us today to explore how our engineering services can elevate your fleet's performance and efficiency.
      </p>
    </div>
  );
}

export default OverhaulExhaustt;

