import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const Header = ({ isDropdownOpen, toggleDropdown }) => {
    const [animated, setAnimated] = useState(false);
    const [showCurtainMenu, setShowCurtainMenu] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileServices, setShowMobileServices] = useState(false);
    const [headerImageLoaded, setHeaderImageLoaded] = useState(false);
    const [showMegaMenu, setShowMegaMenu] = useState(false);
    const location = useLocation();

    const handleToggleMegaMenu = () => {
        setShowMegaMenu(prevState => !prevState);
    };

    const handleToggleSublist = (e) => {
        setShowCurtainMenu(!showCurtainMenu);
        e.stopPropagation();
    };

    const handleToggleServices = () => {
        if (isDropdownOpen) {
            toggleDropdown(false);
        } else {
            toggleDropdown(true);
            setShowMobileServices(true);
            setShowCurtainMenu(true);
        }
    };
    
    const handleExitMenu = () => {
        toggleDropdown(false);
        setShowCurtainMenu(false); 
    };
    
    useEffect(() => {
        setAnimated(true);
    }, []);

    useEffect(() => {
        const setResponsiveness = () => {
          setIsMobile(window.innerWidth <= 1024);
        };
    
        setResponsiveness();
        window.addEventListener('resize', setResponsiveness);
    
        return () => {
          window.removeEventListener('resize', setResponsiveness);
        };
      }, []);

    useEffect(() => {
        if (showCurtainMenu) {
            setShowMobileServices(false);
        }
    }, [showCurtainMenu]);

    const isContactPage = location.pathname === '/contact';
    const isAboutPage = location.pathname === '/about';
    const isPreDockingServicesPage = location.pathname === '/pre-docking';
    const isCylinderMeasurementPage = location.pathname === '/cylinder-measurement';
    const isAlphaLubricationPage = location.pathname === '/alpha-system';
    const isDockingServicesPage = location.pathname === '/docking-services';
    const isEngineeringServicesPage = location.pathname === '/engineering-services';
    const isPerformanceMeasurementPage = location.pathname === '/performance-measurement';
    const isPressureMeasurePage = location.pathname === '/pressure-measure';
    const isOnBoardOverhaulPage = location.pathname === '/onboard-overhaul';
    const isHealthCheckPage = location.pathname === '/health-check';
    const isLargeRepairPage = location.pathname === '/large-repair';
    const isAdjustmentValves = location.pathname === '/adjustment-valves';
    const isReconditionValvesPage = location.pathname === '/recondition-valves';
    const isOverhaulExhaustPage = location.pathname === '/overhoal-exhaust';
    const isOverhaulFuelPage = location.pathname === '/overhoal-fuel-boosters';
    const isGeneralMaintenancePage = location.pathname === '/general-maintenance';
    const isPumpOverhaulPage = location.pathname === '/pump-overhaul';
    const isSupplyEnginePage = location.pathname === '/supply-engine';
    const isMEenginesPage = location.pathname === '/ME-MC-engines';
    const isShaPoliPage = location.pathname === '/shaPoli';
    const isDeRatingPage = location.pathname === '/de-rating';
    const isTroubleShootingPage = location.pathname === '/trouble-shooting';
    const isFuelGasSupplyPage = location.pathname === '/fuel-gas-supply';
    const isMEtrainingPage = location.pathname === '/ME-training';
    
    let headerText, headerImage;
    const companyLogo = "/pics/Di-Tech-logo.webp";

    if (isContactPage) {
        headerText = "Contact Us";
        headerImage = "/pics/example8.webp";
    } else if (isAboutPage) {
        headerText = "About Us";
        headerImage = "/pics/example9.webp";
    } else if (isPreDockingServicesPage) {
        headerText = "Pre-Docking Services";
        headerImage = "/pics/example6.webp";
    } else if (isCylinderMeasurementPage) {
        headerText = "Cylinder Measurement";
        headerImage = "/pics/dock3.webp";
    } else if (isAlphaLubricationPage) {
        headerText = "Alpha Lubricator System";
        headerImage = "/pics/Lubricator.webp";
    } else if (isDockingServicesPage) {
        headerText = "Docking Service for MC/MC-C ME-B, ME-C, ME-GI, ME-GA, LGIP, LGIM";
        headerImage = "/pics/example10.webp";
    } else if (isEngineeringServicesPage) {
        headerText = "Engineering Services for Retrofit";
        headerImage = "/pics/example3.webp";
    } else if (isPerformanceMeasurementPage) {
        headerText = "Performance Measurement and evaluation";
        headerImage = "/pics/work15.webp";
    } else if (isPressureMeasurePage) {
        headerText = "Real time Pressure measuring instrument";
        headerImage = "/pics/water2.webp";
    } else if (isOnBoardOverhaulPage) {
        headerText = "On board overhaul of FIVA/ELFI/ELVA";
        headerImage = "/pics/backgrnd6.webp";
    } else if (isHealthCheckPage) {
        headerText = "Health check of engines";
        headerImage = "/pics/water3.webp";
    } else if (isAdjustmentValves) {
        headerText = "Overhaul, testing and adjustment of fuel injection valves";
        headerImage = "/pics/parts1.webp";
    } else if (isReconditionValvesPage) {
        headerText = "Reconditioning of exhaust valve spindles and seats";
        headerImage = "/pics/backgrnd4.webp";
    } else if (isOverhaulExhaustPage) {
        headerText = "Overhaul of Exhaust valves onboard and in workshop";
        headerImage = "/pics/dock.webp";
    } else if (isOverhaulFuelPage) {
        headerText = "Overhaul of Fuel boosters";
        headerImage = "/pics/water3.webp";
    } else if (isGeneralMaintenancePage) {
        headerText = "General Maintenance";
        headerImage = "/pics/dock2.webp";
    } else if (isPumpOverhaulPage) {
        headerText = "HPS pump workshop overhauling";
        headerImage = "/pics/dock3.webp";
    } else if (isSupplyEnginePage) {
        headerText = "Supply of engine driven hydraulic pumps and proportional valves made by Danfoss";
        headerImage = "/pics/dock4.webp";
    } else if (isMEenginesPage) {
        headerText = "EEXI-EPL ME/MC engines";
        headerImage = "/pics/example5.webp";
    } else if (isShaPoliPage) {
        headerText = "Shaft Power Limitation";
        headerImage = "/pics/backgrnd4.webp";
    } else if (isDeRatingPage) {
        headerText = "De-rating";
        headerImage = "/pics/water2.webp";
    } else if (isTroubleShootingPage) {
        headerText = "TroubleShooting";
        headerImage = "/pics/work19.webp";
    } else if (isMEtrainingPage) {
        headerText = "Onboard ME Training";
        headerImage = "/pics/training.webp";
    } else if (isLargeRepairPage) {
        headerText = "Large Repair of Engines";
        headerImage = "/pics/backgrnd2.webp";
    } else if (isFuelGasSupplyPage) {
        headerText = "Fuel Gas Supply";
        headerImage = "/pics/backgrnd5.webp";
    } else {
        headerText = "We offer specialized services to MAN B&W two-stroke engines";
        headerImage = "/pics/example4.webp";
    }    

    useEffect(() => {
        const img = new Image();
        img.src = `${process.env.PUBLIC_URL}${headerImage}`;
        img.onload = () => setHeaderImageLoaded(true);
    }, [headerImage]);
    
    return (
        <div className={`header-wrapper ${animated ? 'animated' : ''}`} style={{ backgroundImage: headerImageLoaded ? `url(${process.env.PUBLIC_URL}${headerImage})` : 'none' }}>
        <Helmet>
        <title>{headerText}</title>
        <meta name="description" content={`Discover ${headerText} services and solutions provided by our expert marine engineering team.`} />
        </Helmet>

        <Link className="navbar-brand" to="/">
        <img 
            src={`${process.env.PUBLIC_URL}${companyLogo}`}
            alt="marine engineer"
            className="company-icon-header"
            loading="lazy"
            height="150px"
            width="auto"
            title="Di-Tech Marine Engineering Logo"/>
        </Link>
            
        <div className={`heading-container ${isContactPage ? 'contact-page' : ''} ${isAboutPage ? 'about-page' : ''}`}>
            <h1 className="heading">{headerText}</h1>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
            <button
                className={`navbar-toggler custom-toggler ${isDropdownOpen ? 'dropdown-open' : ''}`}
                type="button"
                onClick={handleToggleSublist}>
            <span className="navbar-toggler-icon"></span>
            <span className="dropdown-line"></span>
            </button>

            <div className={`collapse navbar-collapse ${isMobile ? 'hide' : ''}`} id="navbarNav">
                <ul className={`navbar-nav ${isMobile ? '' : 'ml-auto'}`}>
                    <li className="nav-item active">
                    <Link className="nav-link" to="/">Home</Link>
                    </li>
                <li className="nav-item active mega-menu">
                    <button className="nav-link" onClick={handleToggleMegaMenu}>
                        Services
                </button>
                {showMegaMenu && (
                    <div className="mega-menu-content">
                        <div className="row">
                            <div className="col">
                            <ul>
                                <li><Link to="/pre-docking" onClick={handleToggleMegaMenu}>Pre-Docking inspection</Link></li>
                                <li><Link to="/docking-services" onClick={handleToggleMegaMenu}>Docking services</Link></li>
                                <li><Link to="/cylinder-measurement" onClick={handleToggleMegaMenu}>Cylinder liner measurement</Link></li>
                                <li><Link to="/engineering-services" onClick={handleToggleMegaMenu}>Engineering services for retrofit</Link></li>
                                <li><Link to="/alpha-system" onClick={handleToggleMegaMenu}>Alpha lubricator system</Link></li>
                            </ul>
                            </div>
                            <div className="col">
                            <ul>
                                <li><Link to="/performance-measurement" onClick={handleToggleMegaMenu}>Performance measurement and evaluation</Link></li>
                                <li><Link to="/pressure-measure" onClick={handleToggleMegaMenu}>Real time Pressure measuring instrument</Link></li>
                                <li><Link to="/onboard-overhaul" onClick={handleToggleMegaMenu}>Onboard overhaul of FIVA/ELFI/ELVA</Link></li>
                                <li><Link to="/health-check" onClick={handleToggleMegaMenu}>Health check of engines</Link></li>
                                <li><Link to="/large-repair" onClick={handleToggleMegaMenu}>Large repair</Link></li>
                            </ul>
                            </div>
                            <div className="col">
                            <ul>
                                <li><Link to="/adjustment-valves" onClick={handleToggleMegaMenu}>Overhaul, testing and adjustment of fuel injection valves</Link></li>
                                <li><Link to="/recondition-valves" onClick={handleToggleMegaMenu}>Reconditioning of exhaust valve spindles and seats</Link></li>
                                <li><Link to="/overhoal-exhaust" onClick={handleToggleMegaMenu}>Overhaul of Exhaust valves onboard and in workshop</Link></li>
                                <li><Link to="/overhoal-fuel-boosters" onClick={handleToggleMegaMenu}>Overhaul of Fuel boosters/Exhaust valve actuators</Link></li>
                                <li><Link to="/general-maintenance" onClick={handleToggleMegaMenu}>General maintenance (Piston pull, bearing replacement etc)</Link></li>
                            </ul>
                            </div>
                            <div className="col">
                            <ul>
                                <li><Link to="/pump-overhaul" onClick={handleToggleMegaMenu}>FIVA, HPS pump workshop overhauling (ME-C/ME-B)</Link></li>
                                <li><Link to="/supply-engine" onClick={handleToggleMegaMenu}>Supply of engine driven hydraulic pumps, made by Danfoss (Eaton)</Link></li>
                                <li><Link to="/ME-MC-engines" onClick={handleToggleMegaMenu}>EEXI-EPL ME/MC engines</Link></li>
                                <li><Link to="/shaPoli" onClick={handleToggleMegaMenu}>ShaPoLi (Shaft Power Limitation)</Link></li>
                                <li><Link to="/de-rating" onClick={handleToggleMegaMenu}>De-rating</Link></li>
                                <li><Link to="/trouble-shooting" onClick={handleToggleMegaMenu}>Troubleshooting</Link></li>
                                <li><Link to="/fuel-gas-supply" onClick={handleToggleMegaMenu}>Fuel Gas Supply</Link></li>
                                <li><Link to="/ME-training" onClick={handleToggleMegaMenu}>Onboard ME Training, also covering dual fuel engines</Link></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    )}
                </li>
                            
                    <li className="nav-item active">
                        <Link className="nav-link" to="/about">About</Link>
                    </li>
                        <li className="nav-item active">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        {isMobile && showCurtainMenu && (
        <div className="curtain-menu-header">
            <button className="back-button" onClick={handleExitMenu}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <ul className="curtain-menu-items">
                <li><Link to="/" onClick={handleExitMenu}>Home</Link></li>
                <li><Link to="/about" onClick={handleExitMenu}>About</Link></li>
                <li>
            <button
                className="accordion-button collapsed custom-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={handleToggleServices}>
                Services
            </button>
                <div
                id="flush-collapseOne"
                className={`panel collapse ${showMobileServices ? 'show' : ''}`}>
                <ul>
                    <li><Link to="/pre-docking" onClick={handleExitMenu}>Pre-Docking inspection</Link></li>
                    <li><Link to="/docking-services" onClick={handleExitMenu}>Docking services</Link></li>
                    <li><Link to="/cylinder-measurement" onClick={handleExitMenu}>Cylinder liner measurement</Link></li>
                    <li><Link to="/engineering-services" onClick={handleExitMenu}>Engineering services for retrofit</Link></li>
                    <li><Link to="/alpha-system" onClick={handleExitMenu}>Alpha lubricator system</Link></li>
                    <li><Link to="/performance-measurement" onClick={handleExitMenu}>Performance measurement and evaluation</Link></li>
                    <li><Link to="/pressure-measure" onClick={handleExitMenu}>Real time Pressure measuring instrument</Link></li>
                    <li><Link to="/onboard-overhaul" onClick={handleExitMenu}>Onboard overhaul of FIVA/ELFI/ELVA</Link></li>
                    <li><Link to="/health-check" onClick={handleExitMenu}>Health check of engines</Link></li>
                    <li><Link to="/large-repair" onClick={handleExitMenu}>Large repair</Link></li>
                    <li><Link to="adjustment-valves" onClick={handleExitMenu}>Overhaul, testing and adjustment of fuel injection valves</Link></li>
                    <li><Link to="/recondition-valves" onClick={handleExitMenu}>Reconditioning of exhaust valve spindles and seats</Link></li>
                    <li><Link to="/overhoal-exhaust" onClick={handleExitMenu}>Overhaul of Exhaust valves onboard and in workshop</Link></li>
                    <li><Link to="/overhoal-fuel-boosters" onClick={handleExitMenu}>Overhaul of Fuel boosters/Exhaust valve actuators</Link></li>
                    <li><Link to="/general-maintenance" onClick={handleExitMenu}>General maintenance (Piston pull, bearing replacement etc)</Link></li>
                    <li><Link to="/pump-overhaul" onClick={handleExitMenu}>FIVA, HPS pump workshop overhauling (ME-C/ME-B)</Link></li>
                    <li><Link to="/supply-engine" onClick={handleExitMenu}>Supply of engine driven hydraulic pumps, made by Danfoss (Eaton)</Link></li>
                    <li><Link to="/ME-MC-engines" onClick={handleExitMenu}>EEXI-EPL ME/MC engines</Link></li>
                    <li><Link to="/shaPoli" onClick={handleExitMenu}>ShaPoLi (Shaft Power Limitation)</Link></li>
                    <li><Link to="/de-rating" onClick={handleExitMenu}>De-rating</Link></li>
                    <li><Link to="/trouble-shooting" onClick={handleExitMenu}>Troubleshooting</Link></li>
                    <li><Link to="/fuel-gas-supply" onClick={handleExitMenu}>Fuel Gas Supply</Link></li>
                    <li><Link to="/ME-training" onClick={handleExitMenu}>Onboard ME Training, also covering dual fuel engines</Link></li>
                </ul>
            </div>
        </li>
            <li><Link to="/contact" onClick={handleExitMenu}>Contact</Link></li>
        </ul>
    </div>
 )}
</div>
    );
};

export default Header;







