import React from 'react';
import { Helmet } from 'react-helmet';
import './ReconditionValves.css';

function ReconditionValves() {
  return (
    <div className="recondition-valves">
      <Helmet>
        <title>Reconditioning of exhaust valve spindles and seats - Di-Tech Marine Engineering</title>
        <meta name="description" content="Reconditioning of exhaust valve spindles and seats for optimal engine performance." />
        <meta name="keywords" content="exhaust valve, spindle, seat, reconditioning, engine performance" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Reconditioning of exhaust valve spindles and seats - Di-Tech Marine Engineering" />
        <meta property="og:description" content="Reconditioning of exhaust valve spindles and seats for optimal engine performance." />
        <meta property="og:url" content="https://di-tech.eu/recondition-valves" />
        <meta property="og:type" content="website" />
      </Helmet>

      <h1 className="headingofRecondition">Reconditioning of exhaust valve spindles and seats</h1>
      <p></p>
      <p className='extraParagraph'>
        Contact us today to explore how our engineering services can elevate your fleet's performance and efficiency.
      </p>
    </div>
  );
}

export default ReconditionValves;
