import React from 'react';
import { Helmet } from 'react-helmet';
import './PreDockingServices.css';

function PreDockingServices() {
  return (
    <div className="column-container">
      <Helmet>
        <title>Pre-Docking Services - Di-Tech Marine Engineering</title>
        <meta name="description" content="Discover our pre-docking services for MC/MC-C, ME/ME-C, and ME-B series engines, including visual inspections, measurements, and tasks to be carried out by vessel crew." />
        <meta name="keywords" content="pre-docking services, MC/MC-C series, ME/ME-C series, ME-B series, engine inspection, visual inspection, measurement, vessel crew tasks" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Pre-Docking Services - Di-Tech Marine Engineering" />
        <meta property="og:description" content="Discover our pre-docking services for MC/MC-C, ME/ME-C, and ME-B series engines, including visual inspections, measurements, and tasks to be carried out by vessel crew." />
        <meta property="og:url" content="https://di-tech.eu/pre-docking-services" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="column">
        <div className="column-heading">
          <h2>MC/MC-C Series</h2>
        </div>
        <p className='heading-list-secondary'>Carried out by MAN PrimeServ superintendent engineers</p>
        <p className='heading-list-secondary'>Visual inspection of the following:</p>
        <ul>
          <li>Ball joints for pull rods to governor, fuel rack, and maneuvering gear</li>
          <li>Surfaces of all cams/rollers through the inspection hole in the camshaft housing</li>
          <li>Exhaust high-pressure piping</li>
          <li>Chain drive and guide bars</li>
          <li>Moment compensator</li>
          <li>Water mist catcher</li>
          <li>Thrust bearing</li>
          <li>Earthing device</li>
          <li>Cylinder lubricating system</li>
          <li>Check up on spare parts</li>
          <li>Exhaust Valve drop down test</li>
          <li>Accumulator pressure test</li>
        </ul>
        <p className='heading-list'><strong>Inspection and measurement of the following: </strong></p>
        <ul>
          <li>Cylinder liners, pistons, piston rings (port inspection)</li>
          <li>VAK measuring of cylinder liners and combustion chambers</li>
          <li>Video inspection of cylinder liners and combustion chambers as required</li>
        </ul>
        <hr style={{ width: '50%', margin: 'auto', padding: '20px' }} />
        <p className='heading-list'><strong>To be carried out by vessel crew</strong></p>
        <p className='heading-list-secondary'>Tasks to be completed before inspection:</p>
        <ul>
          <li>Engine performance test</li>
          <li>Crankcase inspection, including checks of bearing clearance, guide rails and piston rods</li>
          <li>Crankshaft deflection</li>
        </ul>
        <p className='heading-list-secondary'>Supplied by crew during attendance:</p>
        <ul>
          <li>Running hours of various components to be collected (list will be provided by the attending superintendent engineers)</li>
        </ul>
      </div>
      <div className="column">
        <div className="column-heading">
          <h2>ME/ME-C Series</h2>
        </div>
        <p className='heading-list-secondary'>Carried out by MAN PrimeServ superintendent engineer</p>
        <p className='heading-list-secondary'>Visual inspection of the following:</p>
        <ul>
          <li>Exhaust high-pressure piping</li>
          <li>Fuel oil boosters</li>
          <li>Hydraulic pumps</li>
          <li>Chain drive and guide bars</li>
          <li>Moment compensator</li>
          <li>Thrust bearing</li>
          <li>Water mist catcher</li>
          <li>Earthing device</li>
          <li>Cylinder lubricating system</li>
          <li>Tacho system</li>
          <li>Check up on spare parts</li>
          <li>Exhaust Valve drop down test</li>
          <li>Accumulator pressure test</li>
        </ul>
        <p className='heading-list'><strong>Inspection and measurement of the following: </strong></p>
        <ul>
          <li>Cylinder liners, pistons, piston rings (port inspection)</li>
          <li>VAK measuring of cylinder liners and combustion chambers</li>
          <li>Video inspection of cylinder liners and combustion chambers as required</li>
        </ul>
        <hr style={{ width: '50%', margin: 'auto', padding: '20px' }} />
        <p className='heading-list'><strong>To be carried out by vessel crew</strong></p>
        <p className='heading-list-secondary'>Tasks to be completed before inspection:</p>
        <ul>
          <li>Engine performance test</li>
          <li>Crankcase inspection, including checks of bearing clearance, guide rails and piston rods</li>
          <li>Crankshaft deflection</li>
        </ul>
        <p className='heading-list-secondary'>Supplied by crew during attendance:</p>
        <ul>
          <li>Running hours of various components to be collected (list will be provided by the attending superintendent engineers)</li>
        </ul>
      </div>
      <div className="column">
        <div className="column-heading">
          <h2>ME-B Series</h2>
        </div>
        <p className='heading-list-secondary'>Carried out by MAN PrimeServ superintendent engineers</p>
        <p className='heading-list-secondary'>Visual inspection of the following:</p>
        <ul>
          <li>Surfaces of all cams/rollers through the inspection hole in the camshaft housing</li>
          <li>Fuel oil boosters</li>
          <li>Hydraulic pumps</li>
          <li>Chain drive and guide bars</li>
          <li>Moment compensator</li>
          <li>Water mist catcher</li>
          <li>Thrust bearing</li>
          <li>Earthing device</li>
          <li>Cylinder lubricating system</li>
          <li>Tacho system</li>
          <li>Check up on spare parts</li>
          <li>Exhaust Valve drop down test</li>
          <li>Accumulator pressure test</li>
        </ul>
        <p className='heading-list'><strong>Inspection and measurement of the following: </strong></p>
        <ul>
          <li>Cylinder liners, pistons, piston rings (port inspection)</li>
          <li>VAK measuring of cylinder liners and combustion chambers</li>
          <li>Video inspection of cylinder liners and combustion chambers as required</li>
        </ul>
        <hr style={{ width: '50%', margin: 'auto', padding: '20px' }} />
        <p className='heading-list'><strong>To be carried out by vessel crew</strong></p>
        <p className='heading-list-secondary'>Tasks to be completed before inspection:</p>
        <ul>
          <li>Engine performance test</li>
          <li>Crankcase inspection, including checks of bearing clearance, guide rails and piston rods</li>
          <li>Crankshaft deflection</li>
        </ul>
        <p className='heading-list-secondary'>Supplied by crew during attendance:</p>
        <ul>
          <li>Running hours of various components to be collected (list will be provided by the attending superintendent engineers)</li>
        </ul>
      </div>
    </div>
  );
}


export default PreDockingServices;